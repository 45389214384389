var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "branchMap" },
    [
      _c("GoogleMapsLoader", {
        ref: "loader",
        attrs: {
          context: _vm.context,
          config: _vm.mapConfig,
          apiKey: _vm.apiKey,
        },
        on: { map__initialized: _vm.onMapLoaded },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var google = ref.google
              var map = ref.map
              return [
                _vm.location !== null
                  ? _c("GoogleMapMarker", {
                      attrs: {
                        google: google,
                        map: map,
                        position: {
                          lat: _vm.location.latitude,
                          lng: _vm.location.longitude,
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.branches, function (branch) {
                  return _c("GoogleMapMarker", {
                    key: branch.id,
                    attrs: {
                      google: google,
                      map: map,
                      position: { lat: branch.latitude, lng: branch.longitude },
                      icon: _vm.getMarkerIcon(branch),
                    },
                    on: {
                      marker__selected: function ($event) {
                        return _vm.onMarkerClick(branch)
                      },
                      marker__created: function ($event) {
                        return _vm.onMarkerCreated(branch, $event)
                      },
                    },
                  })
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }