var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container branchSearch" },
    [
      _c("div", { attrs: { id: "branch-search-map" } }),
      _vm._v(" "),
      _c("BranchSearch_SearchBar", {
        attrs: {
          context: _vm.context,
          location: _vm.location,
          radius: _vm.radius,
        },
        on: {
          location: _vm.onLocationUpdate,
          radius: function ($event) {
            _vm.radius = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "branchSearch__grid" },
        [
          _c("BranchSearch_BranchMap", {
            staticClass: "branchSearch__map",
            attrs: {
              context: _vm.context,
              location: _vm.userLocationProxy,
              branches: _vm.branchesWithDistance,
              "focus-branches": _vm.branchesLimited,
            },
            on: { "marker:click": _vm.onMarkerClick },
          }),
          _vm._v(" "),
          _c("BranchSearch_BranchList", {
            staticClass: "branchSearch__list",
            attrs: {
              "is-select-mode": _vm.isSelectMode,
              branches: _vm.branchesSorted,
            },
            on: { "branch:selected": _vm.onBranchSelected },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }