
























































import {cloneList, filter, isEmpty, map, scrollToTopOf, sort} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import {Branch, MapBranch} from '../../../../Interface/Branch';
import {GeoCodeLocation} from '../../../../Interface/GeoCodeLocation';
import MapUtilsMixin from '../../../../Mixin/MapUtilsMixin';
import {BranchAndGeoLocationService} from '../../../../Service/BranchAndGeoLocationService';
import BranchSearch_BranchList from './Components/BranchList/BranchList.vue';
import BranchSearch_BranchMap from './Components/BranchMap/BranchMap.vue';
import BranchSearch_SearchBar from './Components/SearchBar/SearchBar.vue';

export default {
    name: 'BranchSearch',
    components: {
        BranchSearch_BranchMap,
        BranchSearch_SearchBar,
        BranchSearch_BranchList
    },
    mixins: [MapUtilsMixin],
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        const isSelectMode = this.checkSelectMode();
        return {
            location: isSelectMode ? null : BranchAndGeoLocationService.getCurrentLocation(),
            userLocation: BranchAndGeoLocationService.getCurrentLocation(),

            radius: 50,

            isSelectMode: isSelectMode
        };
    },
    computed: {

        branches(): Array<Branch>
        {
            return this.context.data.get('branches', []);
        },

        branchesWithDistance(): Array<MapBranch>
        {
            return map(this.branches, (branch: MapBranch) => {
                const clone = {...branch};
                clone.distance = this.location === null ? 0 : this.distanceBetweenPoints(this.location, branch);
                return clone;
            });
        },

        branchesSorted(): Array<MapBranch>
        {
            return sort(this.branchesWithDistance, 'distance') as Array<MapBranch>;
        },

        branchesLimited(): Array<MapBranch>
        {
            if (isEmpty(this.location)) {
                return this.branchesSorted.slice(0, 5);
            }

            return filter(this.branchesSorted, v => v.distance < this.radius);
        },

        userLocationProxy(): GeoCodeLocation | null
        {
            if (isEmpty(this.userLocation)) {
                return null;
            }

            if (this.distanceBetweenPoints(this.userLocation, this.branchesSorted[0]) > 4) {
                return this.userLocation;
            }

            return null;
        }
    },
    methods: {
        onLocationUpdate(location: GeoCodeLocation): void
        {
            if (location !== null) {
                this.userLocation = location;
            }

            this.location = location;
            BranchAndGeoLocationService.setCurrentLocation(location);
        },

        onMarkerClick(location: GeoCodeLocation): void
        {
            // Remove the user location, if the user selected a marker outside our radius.
            if (!isEmpty(this.userLocation) &&
                this.distanceBetweenPoints(this.userLocation, location) > this.radius) {
                this.userLocation = null;
            }

            this.location = location;
        },

        onBranchSelected(branch: MapBranch): void
        {
            BranchAndGeoLocationService.setCurrentBranch(cloneList(branch));
            this.isSelectMode = false;
            if (window.location.hash === '#branch-search-map') {
                this.context.pageContext.router.go(-1);
            }
        },

        checkSelectMode(override?: boolean): boolean
        {
            if (override || this.context.store.get(AppStoreKeys.BRANCH_SEARCH_IS_SELECT)) {
                this.isSelectMode = true;
                this.$nextTick(() => this.context.store.set(AppStoreKeys.BRANCH_SEARCH_IS_SELECT, false));
                return true;
            }
            return false;
        }
    },

    watch: {
        isSelectMode(n)
        {
            if (n) {
                this.location = null;
            }
        }
    },
    mounted()
    {
        if (window.location.hash === '#branch-search-map') {
            scrollToTopOf(this.$el, {offset: 200});
        }

        this.$watch(() => this.context.store.get(AppStoreKeys.BRANCH_SEARCH_IS_SELECT), (n) => this.checkSelectMode(n));
    }
};
