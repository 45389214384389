






















































import {asArray, forEach, getPath, isArray, isEmpty} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {ReactiveMap} from '@labor-digital/vuetiful/src/Utils/ReactiveMap';
import {MarkerClusterer} from 'exports-loader?exports=MarkerClusterer!@google/markerclustererplus/dist/markerclustererplus.min.js';
import GoogleMapsLoader from '../../../../../../Component/Branch/BranchMap/GoogleMapLoader.vue';
import GoogleMapMarker from '../../../../../../Component/Branch/BranchMap/GoogleMapMarker.vue';
import {MapBranch} from '../../../../../../Interface/Branch';

export default {
    name: 'BranchSearch_BranchMap',
    components: {GoogleMapMarker, GoogleMapsLoader},
    props: {
        location: {
            type: Object,
            default: null
        },

        context: {
            type: Object,
            required: true
        } as ContentElementContext,

        branches: {
            type: Array,
            required: true
        },

        focusBranches: {
            type: Array,
            required: true
        }
    },
    data()
    {
        return {
            markers: new ReactiveMap(),
            clusterer: null,
            loaded: false
        };
    },
    computed: {
        mapConfig()
        {
            return {
                center: {lat: 51, lng: 10},
                zoom: 6,
                fullscreenControl: false,
                mapTypeControl: false,
                panControl: false,
                zoomControl: true,
                scaleControl: false
            };
        },

        apiKey(): String
        {
            return this.context.pageContext.data.get('googleMapsApiKey');
        },

        closestBranch(): MapBranch | null
        {
            return this.focusBranches[0] ?? null;
        }
    },
    methods: {
        onMapLoaded(): void
        {
            this.loaded = true;
            this.fitBoundsToBranches(this.focusBranches);
        },

        onMarkerCreated(branch: MapBranch, marker): void
        {
            this.markers.set(branch.id, marker);

            if (this.markers.size === this.branches.length) {
                this.makeClusterer();
            }
        },

        onMarkerClick(branch: MapBranch): void
        {
            this.$emit('marker:click',
                {
                    address: branch.city,
                    latitude: branch.latitude,
                    longitude: branch.longitude
                }
            );
        },

        getMarkerIcon(branch: MapBranch): String | Object
        {
            return this.context.pageContext.baseUrl + '/assets/icons/map-pin-' +
                   (branch === this.closestBranch ? 'green' : 'gray') + '.svg';
        },

        fitBoundsToBranches(branches: Array<MapBranch>, previousBranches?: Array<MapBranch>): void
        {
            // Don't do anything until the map is loaded
            if (!this.loaded) {
                return;
            }

            // Special handling if there are no branches
            if (branches.length === 0) {
                return;
            }

            // Don't zoom out if not required
            const map = this.$refs.loader.map;
            if (isArray(previousBranches) && !isEmpty(previousBranches)) {
                const newTarget = branches[0].code;
                const codes = getPath(previousBranches, '*.code');
                if (map.getZoom() > 10 && codes.indexOf(newTarget) !== -1
                    // Force to recalculate if the zoom was changed
                    && codes[0] !== newTarget
                ) {
                    branches = [branches[0]];
                }
            }

            const mapsApi = this.$refs.loader.google.maps;
            const bounds = new mapsApi.LatLngBounds();

            forEach(branches, (branch: MapBranch) => {
                bounds.extend(
                    new mapsApi.LatLng(branch.latitude, branch.longitude)
                );
            });

            map.setOptions({maxZoom: 12, minZoom: 6});
            map.fitBounds(bounds);
            setTimeout(() => map.setOptions({maxZoom: undefined, minZoom: undefined}), 400);
        },

        makeClusterer(): void
        {
            this.clusterer = new MarkerClusterer(
                this.$refs.loader.map,
                asArray(this.markers),
                {
                    imagePath: '/assets/icons/map-cluster-',
                    imageExtension: 'svg',
                    imageSizes: [53]
                });
        },

        destroyClusterer(): void
        {
            if (this.clusterer !== null) {
                this.clusterer.clearMarkers();
            }
        }
    },

    watch: {
        focusBranches(n, o)
        {
            this.fitBoundsToBranches(n, o);
        }
    },

    beforeDestroy(): void
    {
        this.destroyClusterer();
    }
};
