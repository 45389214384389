/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.12.11 at 22:44
 */

function deg2rad(deg)
{
    return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2)
{
    // Source code from: https://stackoverflow.com/a/27943
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

interface SimpleGeoLocation
{
    latitude: number
    longitude: number
}

export default {
    methods: {
        /**
         * Calculates the distance in kilometers between the two given points
         * @param pointA
         * @param pointB
         */
        distanceBetweenPoints(pointA: SimpleGeoLocation, pointB: SimpleGeoLocation): number
        {
            return getDistanceFromLatLonInKm(pointA.latitude, pointA.longitude, pointB.latitude, pointB.longitude);
        }
    }
};
